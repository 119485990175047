import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

//view

import {
  PersonalProfile,
  PersonalExpenses,
  Calendar,
  Timestamp,
  CheckIn,
  CheckOut,
} from '../views/Profile';
//

import { HomeNavbar } from '../components/Nevbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { NotFound } from '../components/Error';
// import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/actions';
import Loading from 'components/Loading';

export function Profile() {
  const module = 'PROFILE';
  const prefix = '/profile';
  const name = 'ข้อมูลส่วนตัว';
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const me = useSelector((state) => state.me);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };
  useEffect(() => {
    dispatch(actions.meGet());

    return () => {};
  }, []);
  if (!me.userData) {
    return <Loading />;
  }
  return (
    <div className="min-h-screen">
      <MainSidebar
        onMobileClose={handleOnMobileNavClose}
        openMobile={isMobileNavOpen}
        me={me}
      />
      <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />

      <div className="relative lg:ml-56  min-h-screen  pt-2 lg:px-8 px-4 ">
        <div className="py-4">
          <Switch>
            {' '}
            <Route exact path={`${prefix}/`}>
              <Calendar title="calendar" subtitle={name} />
            </Route>{' '}
            <Route exact path={`${prefix}/timestamp`}>
              <Timestamp title="timestamp" subtitle={name} />
            </Route>{' '}
            <Route exact path={`${prefix}/timestamp/Check-In`}>
              <CheckIn title="Check-In" subtitle={name} />
            </Route>{' '}
            <Route exact path={`${prefix}/timestamp/Check-out/:id`}>
              <CheckOut title="Check-Out" subtitle={name} />
            </Route>{' '}
            <Route exact path={`${prefix}/profile`}>
              <PersonalProfile title="profile" subtitle={name} />
            </Route>{' '}
            <Route exact path={`${prefix}/disbursement`}>
              <PersonalExpenses title="disbursement" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/calendar`}>
              <Calendar title="calendar" subtitle={name} />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
      </div>
      <div className="lg:ml-64">
        <MainFooter />
      </div>
    </div>
  );
}

export default Profile;
