import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

//view

import {
  HrTimestampList,
  EditTimestamp,
  OtRequest,
  HrEditEmployee,
  HrEmployee,
  HrReport,
  HrReportDaily,
} from '../views/Humen';
//

import { HomeNavbar } from '../components/Nevbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { NotFound } from '../components/Error';
// import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/actions';
import Loading from 'components/Loading';

export function Humen() {
  const module = 'HUMEN';
  const prefix = '/humen';
  const name = 'humen';
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const me = useSelector((state) => state.me);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };
  useEffect(() => {
    dispatch(actions.meGet());

    return () => {};
  }, []);
  if (!me.userData) {
    return <Loading />;
  }
  return (
    <div className="min-h-screen">
      <MainSidebar
        onMobileClose={handleOnMobileNavClose}
        openMobile={isMobileNavOpen}
        me={me}
      />
      <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />

      <div className="relative lg:ml-56  min-h-screen  pt-2 px-8 ">
        <div className="py-4">
          <Switch>
            {' '}
            <Route exact path={`${prefix}/report`}>
              <HrReport title="Report" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/reportDaily`}>
              <HrReportDaily title="Report" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/timestamp`}>
              <HrTimestampList title="Timestamp List" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/timestamp/edit/:id`}>
              <EditTimestamp title="Edit" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/ot-request`}>
              <OtRequest title="OT Request" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/employee`}>
              <HrEmployee title="Employee" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/employee/edit/:id`}>
              <HrEditEmployee title="Edit" subtitle={name} />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
      </div>
      <div className="lg:ml-64">
        <MainFooter />
      </div>
    </div>
  );
}

export default Humen;
