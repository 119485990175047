import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ImageUpload from '../ImageUpload/ImageUpload';

export function RegisterForm({
  errors,
  employee,
  Controller,
  control,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-1">
        <Controller
          name={'username'}
          control={control}

          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="username"
              fullWidth
              size={'small'}
              required
              helperText={errors.username && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="flex w-full">
        <div className="w-1/2 px-1 py-1">
          <Controller
            name={'password'}
            control={control}

            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="password"
                type="password"
                fullWidth
                size={'small'}
                required
                helperText={errors.password && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="w-1/2 px-1 py-1">
          <Controller
            name={'confirmPassword'}
            control={control}

            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="confirmPassword"
                type="password"
                fullWidth
                size={'small'}
                required
                helperText={errors.password && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      </div>


      <div className="w-1/2 px-1 py-1">
        <Controller
          name={'firstname'}
          control={control}

          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="firstname"
              fullWidth
              size={'small'}
              required
              helperText={errors.firstname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-1">
        <Controller
          name={'lastname'}
          control={control}

          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="lastname"
              fullWidth
              size={'small'}
              required
              helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-1">
        <Controller
          name={'firstname_th'}
          control={control}

          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อภาษาไทย"
              fullWidth
              size={'small'}
              required
              helperText={errors.firstname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-1">
        <Controller
          name={'lastname_th'}
          control={control}

          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="นามสกุลภาษาไทย"
              fullWidth
              size={'small'}
              required
              helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'phone_number'}
          control={control}

          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="telephone"
              fullWidth
              size={'small'}
              required
              helperText={errors.phone_number && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

    </div>
  );
}



export default RegisterForm;
