import { Button, Card } from '@mui/material';
import { ViewTitle } from 'components/ViewTitle';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import React, { useState, useEffect } from 'react';
import FilterTimestampCard from '../../components/Card/FilterTimestampCard';
import dayjs from 'dayjs';
import {
  timestampDailyReport,
  timestampDailySalaryReport,
} from '../../components/PDF';
import HrcheckinListReportDaily from 'components/Table/HrcheckinListReportDaily';
import FilterTimestampDailyCard from '../../components/Card/FilterTimestampDailyCard';
import { ButtonGroup } from '../../components/Button';

const options = ['print without salary', 'print with salary'];

export default function HrReportDaily({ title, subtitle }) {
  const dispatch = useDispatch();
  const timestamp = useSelector((state) => state.timestamp);
  const employees = useSelector((state) => state.employee);
  const project = useSelector((state) => state.project);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(30);
  const [emSelect, setEmSelect] = useState();
  const [projectSelect, setProjectSelect] = useState();
  const [dateStart, setDateStart] = useState(dayjs());
  const [dateEnd, setDateEnd] = useState(new Date());
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  useEffect(() => {
    dispatch(
      actions.timestampAll({
        size,
        page,
        emSelect,
        dateStart: dayjs(dateStart).startOf('day').toISOString(),
        dateEnd: dayjs(dateStart).endOf('day').toISOString(),
        sort: true,
        project_id: projectSelect?._id,
      }),
    );
    dispatch(actions.employeeAll({}));
    dispatch(actions.getInformation());

    return () => {};
  }, [page, size, emSelect, dateStart, dateEnd, projectSelect]);

  useEffect(() => {
    dispatch(actions.projectAll({}));

    return () => {};
  }, []);

  const handlePrint = () => {
    const data = { ...timestamp, project: projectSelect, date: dateStart };
    timestampDailyReport(data);
  };

  const handlePrintWithSalary = () => {
    const data = { ...timestamp, project: projectSelect, date: dateStart };
    timestampDailySalaryReport(data);
  };

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
    if (selectedIndex === 0) {
      handlePrint();
    }
    if (selectedIndex === 1) {
      handlePrintWithSalary();
    }
  };

  const renderCardQuery = () => (
    <div>
      <FilterTimestampDailyCard
        employees={employees}
        emSelect={emSelect}
        setEmSelect={setEmSelect}
        dateStart={dateStart}
        dateEnd={dateEnd}
        setDateStart={setDateStart}
        setDateEnd={setDateEnd}
        timestamp={timestamp}
        handlePrint={handlePrint}
        project={project}
        setProjectSelect={setProjectSelect}
        projectSelect={projectSelect}
        renderButtonGrotup={renderButtonGrotup}
      />
    </div>
  );

  const renderTable = () => (
    <HrcheckinListReportDaily
      timestamp={timestamp}
      page={page}
      size={size}
      setPage={setPage}
      setSize={setSize}
    />
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderButtonGrotup = () => (
    <ButtonGroup
      options={options}
      selectedIndex={selectedIndex}
      setSelectedIndex={setSelectedIndex}
      handleClick={handleClick}
    />
  );

  return (
    <div>
      {renderTitle()}
      <div className="py-1">{renderCardQuery()}</div>

      <div>{renderTable()}</div>
    </div>
  );
}
