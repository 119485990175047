import React, { useState, useEffect } from 'react';
import { TextField, Button, Card, Box, IconButton } from '@mui/material';
import { Scanner } from '@yudiel/react-qr-scanner';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { QrReader } from 'react-qr-reader';
import CloseIcon from '@mui/icons-material/Close';
import { Clear } from '@mui/icons-material';
import { ClearIcon } from '@mui/x-date-pickers';
import Cameras from 'components/Camera/Cameras';
import Loading from 'components/Loading';

export default function ManpowerCheckin() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const employee = useSelector((state) => state.employee);
  const project = useSelector((state) => state.project);
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString(),
  );
  const [inputText, setInputText] = useState('');
  const [open, setOpen] = useState(false);
  const [openScan, setOpenScan] = useState(true);
  const [imgSrc, setImgSrc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emSelect, setEmSelect] = useState();

  const [showCamera, setShowCamera] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    dispatch(actions.projectGet(id));
  }, []);

  const handleScan = async (data) => {
    await dispatch(actions.employeeTimestamp({ em: data }));
    setOpenScan(false);
    // console.log(data);
  };

  const handleRescan = async () => {
    await dispatch(actions.employeeReset());
    setOpenScan(true);
    setShowCamera(false);
    setImgSrc(null);
  };

  const renderCameraButton = () => (
    <div className="flex justify-center my-4">
      <Button
        variant="contained"
        size="large"
        onClick={() => setShowCamera(true)}
      >
        เปิดกล้อง
      </Button>
    </div>
  );

  // const handleButtonClick = () => {
  //   if (_.isEmpty(inputText)) {
  //     alert('กรุณากรอกรหัสพนักงาน');
  //   }
  //   //  else if (inputText.length !== 8) {
  //   //   alert('รหัสพนักงานต้องมีความยาว 8 ตัวอักษร');
  //   // }
  //   else {
  //     dispatch(actions.employeeTimestamp({ em: inputText }));
  //   }
  // };

  const onSubmit = async (data) => {
    if (!imgSrc) {
      alert('Please select a project');
    } else {
      const confirm = window.confirm('save');
      if (confirm) {
        setLoading(true);
        const dataSubmit = {
          employee: data?._id,
          employeekey: data?.check_key,
          employee_firstname: data?.firstname,
          employee_lastname: data?.lastname,
          salary: data?.salary,
          salary_extra: data?.salary_extra,
          status_checkIn: true,
          project_in: project,
          image: imgSrc,
        };
        await dispatch(actions.timestampCreate(dataSubmit));
        setLoading(false);
        setImgSrc(null);
        handleRescan();
        // // await dispatch(actions.timestampAll({}));
        // history.goBack();
      }
    }
  };

  const QRReader = () => (
    <div>
      <div className="flex justify-center">
        <Box sx={{ width: '50%', height: '50%' }}>
          <Scanner
            onScan={(results) => {
              console.log('Scanner results:', results);
              handleScan(results?.[0]?.rawValue);
            }} 
             allowMultiple
          />
        </Box>
      </div>
    </div>
  );

  const renderCheck = () => {
    setOpen(true);
  };

  const renderImage = () => (
    <div>
      {!showCamera ? (
        renderCameraButton()
      ) : (
        <Box>
          <div>
            <div className="flex justify-center border">
              <div>
                <Cameras imgSrc={imgSrc} setImgSrc={setImgSrc} />
              </div>
            </div>
          </div>
        </Box>
      )}
    </div>
  );

  const renderFormEmployee = () => (
    <div className="flex justify-center py-16">
      <div>
        <h1 className="lg:text-4xl font-bold mb-4 text-center text-theme-600 ">
          ลงเวลาเข้างาน
        </h1>
        <div className="flex justify-center items-center">
          <div className="border-b-2 border-theme-600 w-1/2 my-2  "> </div>
        </div>
        <div className="text-xl mb-8 text-center  text-theme-500 ">
          โครงการ {project?.name}
        </div>
        <div className="text-2xl text-center ">{currentTime}</div>

        {openScan && (
          <div>
            <div className="text-center text-xl ">สแกน QR code</div>
            {QRReader()}
          </div>
        )}
      </div>
    </div>
  );

  const renderEmployeeList = () => (
    <div>
      {_.isEmpty(employee?.rows) ? (
        <div className="flex justify-center">
          {!openScan && (
            <div>
              <div>ไม่มีข้อมูลพนักงาน</div>
              <div className="flex justify-center ">
                <IconButton onClick={() => handleRescan()} aria-label="delete">
                  <ClearIcon fontSize="large" />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          {_.map(employee?.rows, (row, index) => (
            <div className="flex justify-center">
              <div>
                <Card sx={{ minWidth: 300 }}>
                  <div className="p-2">
                    <div>
                      <div className="flex justify-between text-xl ">
                        <div className="pt-4">
                          <b className="text-theme-600">ชื่อ-สกุล</b>{' '}
                          {row?.firstname} {row?.lastname}
                        </div>

                        <div className="px-2">
                          <IconButton
                            onClick={() => handleRescan()}
                            aria-label="delete"
                          >
                            <ClearIcon fontSize="large" />
                          </IconButton>
                        </div>
                      </div>
                      <div className="text-xl">
                        <b className="text-theme-600">ทีม</b> {row?.team || '-'}
                      </div>
                      <div>{renderImage()}</div>

                      {imgSrc && ( // แสดงปุ่มบันทึกเมื่อมีรูปภาพแล้วเท่านั้น
                        <div className="flex justify-center p-4">
                          <Button
                            variant="contained"
                            size="large"
                            onClick={() => onSubmit(row)}
                          >
                            บันทึก
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div>
      {loading ? (
        <Loading loading />
      ) : (
        <div className="flex flex-col h-screen bg-gray-100">
          {renderFormEmployee()}

          {renderEmployeeList()}
        </div>
      )}
    </div>
  );
}
