import { Button, Card } from '@mui/material';
import { ViewTitle } from 'components/ViewTitle';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import HrcheckinList from '../../components/Table/HrcheckinList';
import FilterTimestampCard from '../../components/Card/FilterTimestampCard';
import dayjs from 'dayjs';
import { timestampReport } from '../../components/PDF';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import EditTimestamp from './EditTimestamp';
import EditTimestampTime from 'components/Forms/EditTimestampTime';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  boxShadow: 24,
  bgcolor: 'background.paper',
  p: 4,
};

export default function HrTimestampList({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const timestamp = useSelector((state) => state.timestamp);
  const employees = useSelector((state) => state.employee);
  const info = useSelector((state) => state.info);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(9);
  const [emSelect, setEmSelect] = useState();
  const [dateStart, setDateStart] = useState(dayjs());
  const [dateEnd, setDateEnd] = useState(new Date());
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(dayjs());
  const [checkin, setCheckin] = useState();
  const [openEditCheckin, setOpenCheckin] = React.useState(false);
  const [valueCheckIn, setValueCheckin] = React.useState(dayjs());

  useEffect(() => {
    dispatch(
      actions.timestampAll({
        emSelect,
        dateStart: dayjs(dateStart).startOf('day').toISOString(),
        dateEnd: dayjs(dateEnd).endOf('day').toISOString(),
      }),
    );
    dispatch(actions.employeeAll({}));
    dispatch(actions.getInformation());

    return () => {};
  }, [page, size, emSelect, dateStart, dateEnd]);

  const handleCheckout = async () => {
    const cofirm = window.confirm('confirm');
    if (cofirm) {
      const datasubmit = {
        ...checkin,
        checkOut: value.toISOString(),
        status_checkOut: true,
      };
      await dispatch(actions.timestampOtRequire(checkin?.id, { datasubmit }));
      dispatch(
        actions.timestampAll({
          size,
          page,
          emSelect,
          dateStart: dayjs(dateStart).startOf('day').toISOString(),
          dateEnd: dayjs(dateEnd).endOf('day').toISOString(),
        }),
      );
      setOpen(false);
    }
  };

  const handleCheckin = async () => {
    const cofirm = window.confirm('confirm');
    if (cofirm) {
      const datasubmit = {
        ...checkin,
        checkIn: valueCheckIn.toISOString(),
        status_checkOut: true,
      };
      await dispatch(actions.timestampOtRequire(checkin?.id, { datasubmit }));
      dispatch(
        actions.timestampAll({
          size,
          page,
          emSelect,
          dateStart: dayjs(dateStart).startOf('day').toISOString(),
          dateEnd: dayjs(dateEnd).endOf('day').toISOString(),
        }),
      );
      setOpenCheckin(false);
    }
  };

  const handleOpen = (each) => {
    setOpen(true);
    setCheckin(each);
  };
  const handleOpenEditCheckin = (each) => {
    setOpenCheckin(true);
    setCheckin(each);
  };
  const handleClose = () => setOpen(false);

  const handleCloseEditCheckin = () => setOpenCheckin(false);

  const renderDelete = async (id) => {
    const confirm = window.confirm('confirm');
    if (confirm) {
      await dispatch(actions.timestampDelete(id));
      dispatch(actions.timestampAll({ size, page, emSelect }));
    }
  };
  const handlePrint = () => {
    timestampReport(timestamp);
  };

  const renderModalCheckin = (data) => (
    <div>
      <Modal
        open={openEditCheckin}
        onClose={handleCloseEditCheckin}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Check in
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <EditTimestampTime
              value={valueCheckIn}
              setValue={setValueCheckin}
            />
            <div>
              <Button onClick={() => handleCheckin(data)}>submit</Button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );

  const renderModal = (data) => (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Checkout
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <EditTimestampTime value={value} setValue={setValue} />
            <div>
              <Button onClick={() => handleCheckout(data)}>submit</Button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );

  const renderCardQuery = () => (
    <div>
      <FilterTimestampCard
        employees={employees}
        emSelect={emSelect}
        setEmSelect={setEmSelect}
        dateStart={dateStart}
        dateEnd={dateEnd}
        setDateStart={setDateStart}
        setDateEnd={setDateEnd}
        timestamp={timestamp}
        handlePrint={handlePrint}
      />
    </div>
  );

  const renderTable = () => (
    <HrcheckinList
      timestamp={timestamp}
      page={page}
      size={size}
      setPage={setPage}
      setSize={setSize}
      show={info?.setting?.timestamp_image}
      renderDelete={renderDelete}
      handleOpen={handleOpen}
      handleOpenEditCheckin={handleOpenEditCheckin}
    />
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      {renderModal()}
      {renderTitle()}
      {renderModalCheckin()}
      <div className="py-1">{renderCardQuery()}</div>
      <div>{renderTable()}</div>
    </div>
  );
}
