import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { HomeNavbar } from '../components/Nevbars/index';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { NotFound } from '../components/Error';
// import { Loading } from '../components/Loading';
// import accessRight from '../utils/functions/accessRight';
import { CreateDropletProject } from './Monitor';
import * as actions from '../redux/actions';
import Loading from 'components/Loading';
import LoginIcon from '@mui/icons-material/Login';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { Card } from '@mui/material';
import { DollarSign, Clock } from 'react-feather';

export default function Home() {
  const dispatch = useDispatch();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const me = useSelector((state) => state.me);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };
  useEffect(() => {
    dispatch(actions.meGet());

    return () => { };
  }, []);
  if (!me.userData) {
    return <Loading />;
  }
  return (
    <div className="">
      <div className="">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
      </div>
      <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
      <div className="flex-1 ml-0 lg:ml-64 justify-center items-center">
        <div className="min-h-screen flex flex-col">
          <div className="lg:flex-row gap-5 text-center p-5 mt-10">
            <div className="flex justify-center mb-4">
              <img src="/favicon.ico" alt="CorePlan Logo" className="w-20 h-20" />
            </div>
            <h3 className="text-3xl font-semibold text-gray-800 mb-2">COREPLAN ERP </h3>

          </div>
          <div className='flex justify-center' >

            <div className="flex flex-col lg:flex-row gap-5 justify-center p-5">
              <div className="bg-white max-w-full sm:max-w-xs shadow-md flex-1 mb-5 lg:mb-0">
                <div className="flex gap-3 items-center bg-green-500 text-center justify-center items-center p-2">
                  <LoginIcon sx={{ fontSize: 30, color: "#fff" }} />
                  <h3 className="text-xl text-white">Work</h3>
                </div>
                <div className="p-2 text-gray-500">
                  <h>Manage and check in to work efficiently</h>
                </div>

                <div className="flex justify-center " >

                  <div className="w-1/2">
                    <Link to={'/profile/timestamp'}>
                      <div className="bg-green-600 shadow rounded text-center p-5 transition-transform transform hover:scale-105">
                        <div className="flex justify-center">
                          <Clock size={50} color="white" />
                        </div>
                        <p className="text-white mt-4">ลงเวลา</p>
                      </div>
                    </Link>
                  </div>
                  <div className="w-1/2">
                    <Link to={'/profile/disbursement'}>
                      <div className="bg-blue-600 shadow rounded text-center p-5 transition-transform transform hover:scale-105">
                        <div className="flex justify-center">
                          <DollarSign size={50} color="white" />
                        </div>
                        <p className="text-white mt-4">เบิกจ่าย</p>
                      </div>
                    </Link>
                  </div>

                </div>



              </div>


            </div>
          </div>
        </div>

        <div>
          <MainFooter />
        </div>
      </div>
    </div>

  );
}
