import { ViewTitle } from 'components/ViewTitle';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import { Avatar, Card } from '@mui/material';

export default function PersonalProfile({ title, subtitle }) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const profile = me?.userData;
  useEffect(() => {
    dispatch(actions.meGet());

    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderDetail = () => {
    return (
      <div className="lg:flex gap-2 ">
        <div className="lg:w-2/4 w-full">
          <Card>
            <div className="flex flex-wrap pt-4">
              <div className="w-full">
                <div className="flex justify-center">
                  {/* <img src={image?.url} className="w-60 rounded-lg" /> */}
                  <Avatar sx={{ width: 150, height: 150 }} />
                </div>
              </div>
              <div className="w-full text-center">
                <div className="text-2xl font-semibold font-display text-blue-800 py-2 mt-2"></div>
                <div className="py-2">
                  {profile?.firstname} {profile?.lastname}{' '}
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="w-full">
          <Card>
            <div className="flex flex-wrap p-4">
              <div className="w-full grid grid-cols-2 ">
                <div className="py-2">
                  <b className="font-bold text-theme-600"> ชื่อ : </b>{' '}
                  {profile?.firstname} {profile?.lastname}{' '}
                </div>
                <div className="py-2">
                  <b className="font-bold text-theme-600"> เบอร์โทรศัพท์ : </b>{' '}
                  {profile?.phone_number}{' '}
                </div>
                <div className="py-2">
                  <b className="font-bold text-theme-600"> ตำแหน่ง : </b>{' '}
                  {profile?.role?.name}{' '}
                </div>
                <div className="py-2">
                  <b className="font-bold text-theme-600"> แผนก : </b>{' '}
                  {profile?.department?.name}{' '}
                </div>
                <div className="py-2">
                  <b className="font-bold text-theme-600"> เงินเดือน : </b>{' '}
                  {profile?.department?.name}{' '}
                </div>
                <div className="py-2">
                  <b className="font-bold text-theme-600"> วันที่เริ่มงาน : </b>{' '}
                  {profile?.department?.name}{' '}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  };
  const renderLeve = () => (
    <div className="w-full py-1">
      <Card sx={{ height: 300 }}>
        <div className="py-2">
          <div className="p-2 ">
            <b className="font-bold text-theme-600"> เอกสารใบลา : </b>{' '}
          </div>
        </div>
      </Card>
    </div>
  );
  const rendertimestamp = () => (
    <div className="w-full py-1">
      <Card sx={{ height: 300 }}>
        <div className="py-2">
          <div className="p-2 ">
            <b className="font-bold text-theme-600"> รายการลงเวลา : </b>{' '}
          </div>
        </div>
      </Card>
    </div>
  );
  const renderProject = () => (
    <div className="w-full py-1">
      <Card sx={{ height: 300 }}>
        <div className="py-2">
          <div className="p-2 ">
            <b className="font-bold text-theme-600"> รายการโครงการ : </b>{' '}
          </div>
        </div>
      </Card>
    </div>
  );

  return (
    <div>
      {renderTitle()}
      {renderDetail()}
      <div className="lg:grid grid-cols-3 gap-2 py-2">
        {renderLeve()}
        {rendertimestamp()}
        {renderProject()}
      </div>
    </div>
  );
}
