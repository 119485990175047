import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AccessTime } from "@mui/icons-material";
import dayjs from "dayjs";
import { Avatar, Card } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LoginIcon from '@mui/icons-material/Login';

const TimeStampMain= () => {
    const [ctime, setTime] = useState(new Date().toLocaleTimeString());
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me);
    const profile = me?.userData;
    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date().toLocaleTimeString());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div>
            <div className="flex flex-col sm:flex-row gap-5 justify-center ">
                <div className="bg-white flex-1 max-w-full h-70 sm:max-w-xs p-5 shadow-md">
                    <div className="flex flex-wrap pt-2">
                        <div className="w-full">
                            <div className="flex justify-center items-theme-400 ">
                                <Avatar sx={{ width: 150, height: 150 }} />
                            </div>
                        </div>
                        <div className="w-full text-center">
                            <div className="text-2xl font-semibold font-display text-blue-800 py-2 mt-2"></div>
                            <div className="py-2">
                                {profile?.firstname} {profile?.lastname}{' '}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white flex-1 max-w-full sm:max-w-xl p-6 shadow-md">
                    <div>
                        <div  className="flex gap-3 mb-2 items-center ">
                            <div  className="bg-green-500 p-1 rounded-lg shadow-md ">
                                <LoginIcon sx={{ fontSize: 40 ,color: "#fff" }} />
                            </div>
                            <h3 className="text-3xl font-semibold text-gray-800 mb-2">Work Check-In</h3>
                        </div>
                        <p className="text-gray-600 mb-6">
                             Manage and check in to work efficiently. Review details below.
                        </p>
        
                    </div>
                    <div className="flex justify-center items-center border p-3">
                        <div className="flex p-3 gap-5 justify-center items-center  max-w-full sm:max-w-xl ">
                            <div className="bg-white text-xl font-bold text-center p-6 flex-1">
                                <div>{dayjs(new Date()).format("DD MMMM YYYY")}</div>
                                <div className="text-lg text-gray-700">{ctime}</div>
                            </div>
                        </div>
                        <div className="flex  justify-center items-center max-w-full sm:max-w-xs ">
                            <Link to="/profile/timestamp/check-In">
                                <div className="bg-theme-400 text-white text-center p-3 w-full rounded-lg shadow-md transition-all transform hover:scale-105 flex-1">
                                    <AccessTime sx={{ fontSize: 50, color: "#fff" }} />
                                    <p className="text-white text-xl mt-3">ลงเวลาเข้างาน</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TimeStampMain;
