import React, { useState, useEffect } from 'react';
import { ViewTitle } from 'components/ViewTitle';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectForm } from 'components/Forms';
import * as actions from '../../redux/actions';
import { useForm, Controller } from 'react-hook-form';
import { Button, Card } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function NewProject({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const employee = useSelector((state) => state.employee);

  const [date, setDate] = useState(new Date());
  const [dateEnd, setDateEnd] = useState(new Date());

  useEffect(() => {
    dispatch(actions.employeeAll({}));

    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึก');
    if (confirm) {
      const dataSubmit = {
        ...data,
        date_start: date,
        date_end: dateEnd,
      };
      await dispatch(actions?.projectCreate(dataSubmit));
      dispatch(actions?.projectAll({}));
      history.goBack();
    }
  };

  const renderForm = () => (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'p-2'}>
          <ProjectForm
            Controller={Controller}
            control={control}
            date={date}
            setDate={setDate}
            dateEnd={dateEnd}
            setDateEnd={setDateEnd}
            employee={employee?.rows}
          />
          <div className="flex justify-center">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </div>
      </form>
    </Card>
  );
  return (
    <div>
      {renderTitle()}
      <div>{renderForm()}</div>
    </div>
  );
}
