/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router';
import { Card, Button, Avatar, Chip } from '@mui/material';
import dayjs from 'dayjs';
import { OT_STATUS } from 'utils/constants';
import MainTimeStamp from './TimeStampMain';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { useDispatch, useSelector } from 'react-redux';
import LoginIcon from '@mui/icons-material/Login';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import LogoutIcon from '@mui/icons-material/Logout';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';

export default function ListTimestamp({ timestamp, setOpen }) {
  const [ctime, setTime] = useState(new Date().toLocaleTimeString());
  const [showDelayed, setShowDelayed] = useState();
  const me = useSelector((state) => state.me);
  const profile = me?.userData;

  const UpdateTime = () => {
    const time = new Date().toLocaleTimeString('th');
    setTime(time);
  };
  setInterval(UpdateTime);
  const history = useHistory();
  const handleClick = () => {
    setTimeout(() => {
      setShowDelayed(true);
    }, 1000);
  };
  return (
    <div className="p-5 flex justify-center ">
      {timestamp?._id ? (
        <div className="bg-white w-full sm:max-w-4xl p-3 gap-2 sm:mx-auto">
          <div className="flex gap-5 sm:flex-row w-full items-stretch mb-3">
            <div className="flex bg-green-500 gap-2 text-white text-xl  p-2 border shadow-md w-full">
              <SaveAsIcon />
              <p>บันทึกการเข้างานสำเร็จ</p>
            </div>
          </div>

          <div className="flex flex-col gap-5 sm:flex-row w-full items-stretch ">
            <div className="gap-10 p-2 text-gray-600 border shadow-md w-full sm:w-1/3 ">
              <div className="flex mb-3 gap-2 justify-center items-center bg-gray-200 w-full p-2">
                <Avatar sx={{ width: 40, height: 40 }} />
                {profile?.firstname} {profile?.lastname}{' '}
              </div>



              <div className="sm:flex-row text-gray-700 gap-3 sm:gap-5 p-2 shadow-md w-full sm:w-2/3 justify-between responsive-show block sm:hidden">
                <div className="">
                  <div className="">
                    <div className="text-center justify-center items-center">
                      <div className="bg-gray-300 flex p-2 font-bold text-xl mb-2  border gap-3">
                        <div className="flex text flex-1 p-2 font-bold text-2xl justify-center items-center gap-2">
                          {dayjs(new Date()).format('DD MMMM YYYY')}
                        </div>
                        <div className="flex text bg-white flex-1 p-2 font-bold text-xl justify-center items-center gap-2 sm:flex-row flex-col">
                          <AccessTimeIcon />
                          {ctime}
                        </div>
                        <div className="flex justify-center">
                          {timestamp?.late_status && <Chip label="เข้างานสาย" color="error" />}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border mt-3 mb-3">
                    <div className="flex gap-2 bg-white p-2">
                      <div className="flex flex-1 text-gray-500 gap-2 font-bold">
                        <WorkHistoryIcon />
                        <p>ประวัติการเข้างาน</p>
                      </div>
                      <div className="flex-1">
                        {timestamp?.ot_status?.require && (
                          <div className="bg-gray-300 p-1 flex gap-2 rounded items-center">
                            <p className="flex-1">สถานะ OT :</p>
                            <Chip
                              label={`${OT_STATUS?.[timestamp?.ot_status?.status]?.status_code}`}
                              color={OT_STATUS?.[timestamp?.ot_status?.status]?.color}
                              size="small"
                              sx={{
                                color: 'white',
                                border: '1px solid white',
                              }}
                            />
                          </div>
                        )}
                      </div>

                    </div>
                    <div className="flex mt-1 gap-2 border ">
                      <div className=" bg-white flex-1 p-1">
                        <b className="font-bold">วันที่ :</b>{' '}
                        {dayjs().format('DD/MM/YYYY')}
                      </div>
                      <div className="bg-white  flex-1 p-1">
                        <b className="font-bold">เวลาเข้างาน :</b>{' '}
                        {dayjs(timestamp?.checkIn).format('HH:mm')}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex w-full gap-3 ">
                  {!timestamp?.ot_status?.approve && (
                    <div className="flex-1 flex p-3 justify-center items-center ">
                      <div className="bg-blue-400 text-white text-center p-3 w-full rounded-lg shadow-md transition-all transform hover:scale-105">
                        <AddToPhotosIcon sx={{ fontSize: 40, color: "#fff" }} />
                        <p
                          disabled={timestamp?.ot_status?.require}
                          onClick={() => setOpen(true)}
                          className="text-white text-xl ">ขอ OT</p>
                      </div>
                    </div>
                  )}
                  <div className="flex-1 flex p-3 justify-center items-center ">
                    <div
                      onClick={() =>
                        history.push(
                          `/profile/timestamp/Check-out/${timestamp?._id}`,
                        )
                      }
                      className="bg-red-600 text-white text-center p-3 w-full rounded-lg shadow-md transition-all transform hover:scale-105">
                      <LogoutIcon sx={{ fontSize: 40, color: "#fff" }} />
                      <p className="text-white text-xl ">ลงเวลาออกงาน</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="justify-center items-center border w-full p-2">
                <div className="gap-2 flex text-white p-1  mb-1 bg-green-500">
                  <CameraAltIcon />
                  Check-In
                </div>
                <img
                  src={timestamp?.image?.url}
                  alt="Image description"
                />
              </div>
            </div>
            <div className="sm:flex-row text-gray-700 gap-3 sm:gap-5 p-2 shadow-md w-full sm:w-2/3 justify-between  hidden sm:block responsive-hide ">
                <div className="">
                  <div className="">
                    <div className="text-center justify-center items-center">
                      <div className="bg-gray-300 flex p-2 font-bold text-xl mb-2  border gap-3">
                        <div className="flex text flex-1 p-2 font-bold text-2xl justify-center items-center gap-2">
                          {dayjs(new Date()).format('DD MMMM YYYY')}
                        </div>
                        <div className="flex text bg-white flex-1 p-2 font-bold text-xl justify-center items-center gap-2 ">
                          <AccessTimeIcon />
                          {ctime}
                        </div>
                        <div className="flex justify-center">
                          {timestamp?.late_status && <Chip label="เข้างานสาย" color="error" />}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border mt-3 mb-3">
                    <div className="flex gap-2 bg-white p-2">
                      <div className="flex flex-1 text-gray-500 gap-2 font-bold">
                        <WorkHistoryIcon />
                        <p>ประวัติการเข้างาน</p>
                      </div>
                      <div className="flex-1">
                        {timestamp?.ot_status?.require && (
                          <div className="bg-gray-300 p-1 flex gap-2 rounded items-center">
                            <p className="flex-1">สถานะ OT :</p>
                            <Chip
                              label={`${OT_STATUS?.[timestamp?.ot_status?.status]?.status_code}`}
                              color={OT_STATUS?.[timestamp?.ot_status?.status]?.color}
                              size="small"
                              sx={{
                                color: 'white',
                                border: '1px solid white',
                              }}
                            />
                          </div>
                        )}
                      </div>

                    </div>
                    <div className="flex mt-1 gap-2 border ">
                      <div className=" bg-white flex-1 p-1">
                        <b className="font-bold">วันที่ :</b>{' '}
                        {dayjs().format('DD/MM/YYYY')}
                      </div>
                      <div className="bg-white  flex-1 p-1">
                        <b className="font-bold">เวลาเข้างาน :</b>{' '}
                        {dayjs(timestamp?.checkIn).format('HH:mm')}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex w-full gap-3 ">
                  {!timestamp?.ot_status?.approve && (
                    <div className="flex-1 flex p-3 justify-center items-center ">
                      <div className="bg-blue-400 text-white text-center p-3 w-full rounded-lg shadow-md transition-all transform hover:scale-105">
                        <AddToPhotosIcon sx={{ fontSize: 40, color: "#fff" }} />
                        <p
                          disabled={timestamp?.ot_status?.require}
                          onClick={() => setOpen(true)}
                          className="text-white text-xl ">ขอ OT</p>
                      </div>
                    </div>
                  )}
                  <div className="flex-1 flex p-3 justify-center items-center ">
                    <div
                      onClick={() =>
                        history.push(
                          `/profile/timestamp/Check-out/${timestamp?._id}`,
                        )
                      }
                      className="bg-red-600 text-white text-center p-3 w-full rounded-lg shadow-md transition-all transform hover:scale-105">
                      <LogoutIcon sx={{ fontSize: 40, color: "#fff" }} />
                      <p className="text-white text-xl ">ลงเวลาออกงาน</p>
                    </div>
                  </div>
                </div>
              </div>

          </div>
        </div>
      ) : (
        <div>
          <MainTimeStamp />
        </div>
      )}

    </div>
  );
}
