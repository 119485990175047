import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
  Chip,
} from '@mui/material';
import dayjs from 'dayjs';
import { OT_STATUS } from 'utils/constants';

export default function OtCheckRe({ timestamp, renderApprove, renderReject }) {
  return (
    <div>
      {' '}
      <TableContainer
        component={Paper}
        // style={{ height: 300, overflow: 'auto' }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow className={'bg-theme-500 text-white'}>
              <TableCell>No</TableCell>
              <TableCell>Employee</TableCell>
              <TableCell>Check In</TableCell>
              <TableCell>Project</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>status</TableCell>
              <TableCell>approve by</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timestamp?.rows?.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  {row?.employee?.firstname} {row?.employee?.lastname}
                </TableCell>
                <TableCell>
                  {dayjs(row?.checkIn).format('DD/MM/YYYY  HH:mm')}
                </TableCell>
                <TableCell>{row?.project_in?.name}</TableCell>
                <TableCell> {row?.ot_status?.description}</TableCell>
                <TableCell>
                  <Chip
                    label={`${
                      OT_STATUS?.[row?.ot_status?.status]?.status_code
                    }`}
                    color={OT_STATUS?.[row?.ot_status?.status]?.color}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  {row?.ot_status?.approver?.firstname}{' '}
                  {row?.ot_status?.approver?.lastname}{' '}
                </TableCell>
                <TableCell>
                  <Stack spacing={1}>
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      onClick={() => renderApprove(row)}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={() => renderReject(row)}
                    >
                      Reject
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
