import React, { useEffect, useState } from 'react';
import { ViewTitle } from 'components/ViewTitle';
import * as actions from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import FilterTimestampReportCard from 'components/Card/FilterTimestampReportCard';

export default function HrReport({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const employees = useSelector((state) => state.employee);
  const timestamp = useSelector((state) => state.timestamp);

  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [emSelect, setEmSelect] = useState();
  const [dateStart, setDateStart] = useState(dayjs());
  const [dateEnd, setDateEnd] = useState(new Date());

  useEffect(() => {
    dispatch(actions.timestampAll({ cal: true, size, page, emSelect }));
    dispatch(actions.employeeAll({}));

    return () => {};
  }, [page, size, emSelect, dateStart, dateEnd]);
  const handlePrint = () => {};

  const renderCardQuery = () => (
    <div>
      <FilterTimestampReportCard
        employees={employees}
        emSelect={emSelect}
        setEmSelect={setEmSelect}
        dateStart={dateStart}
        dateEnd={dateEnd}
        setDateStart={setDateStart}
        setDateEnd={setDateEnd}
        timestamp={timestamp}
        handlePrint={handlePrint}
      />
    </div>
  );

  const renderTable = () => <div></div>;

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  return (
    <div>
      {renderTitle()}
      {renderCardQuery()}
    </div>
  );
}
