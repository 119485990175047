import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';
import * as actions from '../../redux/actions';
import { EmployeeForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Card } from '@mui/material';
import RegisterForm from '../../components/Forms/RegisterForm';
export default function Register() {
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm();

    const onSubmit = async (data) => {
        try {
            console.log(data);


            if (data.password === data.confirmPassword) {
                await dispatch(actions.userRegister(data));
                reset();
                alert('สำเร็จ');
                history.goBack();


            } else {
                alert('กรุณาใส่รหัสผ่านให้ตรงกัน');
            }

        } catch (error) {
            console.error(error);
        }
    };
    return (

        <div className="container mx-auto px-4 py-20">
            <div className="flex content-center items-center justify-center">
                <div className="w-full lg:w-4/12 px-4">
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                            <div className="rounded-t mb-0 px-6 py-6">
                                <div>
                                    <div className="py-2">เพิ่มข้อมูลพนักงาน</div>
                                    <div className="py-2">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <RegisterForm
                                                control={control}
                                                Controller={Controller}
                                                errors={errors}


                                            />
                                            <div className="flex flex-row justify-end gap-1 py-4">
                                                <Button variant="contained" type="submit">
                                                    บันทึก
                                                </Button>

                                            </div>
                                        </form>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}
