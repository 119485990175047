import {
  Table,
  TableHead,
  TableContainer,
  Paper,
  TableCell,
  TableRow,
  TableBody,
  Button,
  TablePagination,
  Card,
  Avatar,
  Modal,
  Box,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import dayjs from 'dayjs';
import React from 'react';
import _ from 'lodash';

import { useHistory } from 'react-router';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function HrcheckinList({
  timestamp,
  page,
  size,
  setPage,
  setSize,
  show,
  renderDelete,
  handleOpen,
}) {
  const history = useHistory();
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };

  const handleOpenModal = (image) => {
    setOpenModal(true);
    setBigImage(image);
  };

  const handleCloseModal = () => setOpenModal(false);

  const [openModal, setOpenModal] = React.useState(false);
  const [bigImage, setBigImage] = React.useState('');

  const renderModal = (image) => (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {console.log(bigImage)}
        <img
          src={bigImage}
          alt="Big Image"
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
      </Box>
    </Modal>
  );
  // const late = _?.filter(checkIn?.arr, (e) => e?.late_status === true);
  // const normal = _?.filter(checkIn?.arr, (e) => e?.late_status === false);

  return (
    <div>
      {renderModal()}
      <Paper>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow className={'bg-gray-600 text-white'}>
                <TableCell>
                  <h1 className="font-bold  text-center  text-white "> No.</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center  text-white "> name</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center  text-white ">
                    {' '}
                    Project
                  </h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center text-white "> Date</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold text-center text-white ">
                    Check in
                  </h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold text-center text-white ">
                    Check out
                  </h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold text-center text-white ">Total</h1>
                </TableCell>

                {show && (
                  <TableCell>
                    {' '}
                    <h1 className="font-bold text-center text-white ">image</h1>
                  </TableCell>
                )}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {timestamp?.rows?.length !== 0 ? (
              <TableBody>
                {timestamp?.rows?.map((e, index) => (
                  <TableRow key={e?._id}>
                    <TableCell>
                      <h1 className=" text-center ">
                        {(page - 1) * size + index + 1}
                      </h1>
                    </TableCell>
                    <TableCell>
                      <h1 className=" text-center ">
                        {e?.employee?.firstname} {e?.employee?.lastname}
                      </h1>
                    </TableCell>
                    <TableCell>
                      <h1 className=" text-center ">{e?.project_in?.name}</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className=" text-center ">
                        {dayjs(e?.checkIn).format('DD/MM/YYYY')}
                      </h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="text-center ">
                        {dayjs(e?.checkIn).format('HH:mm')}
                      </h1>
                    </TableCell>
                    <TableCell>
                      {e?.status_checkOut ? (
                        <div>
                          <h1 className="text-center ">
                            {dayjs(e?.checkOut).format('HH:mm') ||
                              'ยังไม่เช็คชื่อ'}
                          </h1>
                        </div>
                      ) : (
                        <div>
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => handleOpen(e)}
                          >
                            ลงชื่อออก
                          </Button>
                        </div>
                      )}
                    </TableCell>

                    <TableCell>
                      <h1 className="text-center ">
                        {e?.totalPrice
                          ?.toFixed(2)
                          ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                      </h1>
                    </TableCell>
                    {show && (
                      <TableCell>
                        <div className="flex gap-3 w-full p-2">
                          <Card>
                            <Avatar
                              src={e?.image?.url}
                              variant="square"
                              sx={{ width: 60, height: 60 }}
                              onClick={() => handleOpenModal(e?.image?.url)}
                            />

                            <div className="px-2 py-2 text-center text-theme-600">
                              in{' '}
                            </div>
                          </Card>
                          {e?.status_checkOut && (
                            <Card>
                              <Avatar
                                src={e?.image_out?.url}
                                variant="square"
                                sx={{ width: 60, height: 60 }}
                                onClick={() =>
                                  handleOpenModal(e?.image_out?.url)
                                }
                              />
                              <div className="p-2 text-center text-theme-600">
                                out
                              </div>
                              {/* <div className="text-center">ดูเพิ่มเติม</div> */}
                            </Card>
                          )}
                        </div>
                      </TableCell>
                    )}
                    <TableCell>
                      <div className="flex">
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={() => handleOpen(e)}
                        >
                          <ModeEditIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={() => renderDelete(e?._id)}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    No items
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 30, 100]}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={timestamp?.total || 0}
          rowsPerPage={size}
          page={page - 1}
        />
      </Paper>
    </div>
  );
}
